<template>
	<v-container class="mt-n6 pt-0" id="settings" fluid tag="section">
		<v-card>
			<v-card-title class="transparent-2">
				Bulk Uploads
				<v-spacer></v-spacer>
				<v-btn icon color="primary" @click="refresh()">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="pa-4">
				<v-row>
					<v-col :cols="colSize(6, 6, 12, 12)">
						<v-autocomplete
							@input="changeSelectedAgentId()"
							label="Rocket Agent"
							v-model="selectedAgent"
							:items="allAgentsList"
							:itemText="`name`"
							:itemValue="`agent.id`"
							:returnObject="true"
							outlined
							hide-details
						/>
					</v-col>
					<v-col :cols="colSize(6, 6, 12, 12)">
						<v-text-field
							@input="refresh()"
							label="Search"
							v-model="searchString"
							outlined
							hide-details
							clearable
						/>
					</v-col>
					<v-col :cols="colSize(12, 12, 12, 12)">
						<v-autocomplete
							label="Alerts"
							v-model="applicationFilters.alerts"
							:items="alertList"
							:returnObject="false"
							outlined
							hide-details
							clearable
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-text>
				<ApplicationsListing
					:agent_applications_list.sync="customFilteredApplications"
					:agent_applications_list_pagination.sync="
						agent_applications_list_pagination
					"
					@reloadApplications="refresh()"
				/>
			</v-card-text>
		</v-card>
		<bulkUploadActionBtn @reloadApplications="refresh()" />
	</v-container>
</template>

<script>
	import { debounce } from "lodash";
	import { mapState } from "vuex";

	import ApplicationsListing from "@/components/tables/ApplicationsListing";
	import bulkUploadActionBtn from "@/components/action-btn/bulkUploadActionBtn.vue";
	export default {
		components: {
			ApplicationsListing,
			bulkUploadActionBtn,
		},
		data: () => ({
			selectedAgent: null,
			searchString: null,
			applicationFilters: { alerts: null },
			itemsPerPageOptions: [
				{
					text: 25,
					value: 25,
				},
				{
					text: 50,
					value: 50,
				},
				{
					text: 100,
					value: 100,
				},
				{
					text: "All",
					value: 99999,
				},
			],
			alertList: [
				"All Alerts",
				"Red - lead missing critical data, could not auto-launch",
				"Orange - could not send quote, email/phone number was invalid",
				"Yellow - quotes sent via “time-out job” instead of “completed”",
				"Teal - Home quotes “Not-Sent”",
				"Purple - All Other Alerts",
			],
		}),
		mounted() {
			if (this.currentUserRole == "admin") {
				this.selectedAgent = this.allAgentsList[0];
				this.changeSelectedAgentId();
			} else {
				this.selectedAgent = this.currentUser?.agent;
				this.$store.state.selected_agent_id = this.currentUser?.agent?.id;
				this.changeSelectedAgentId();
			}
		},
		computed: {
			...mapState({
				agent_applications_list: (state) =>
					state.application.agent_applications_list,
				agent_applications_list_pagination: (state) =>
					state.application.agent_applications_list_pagination,
			}),
			allAgentsList() {
				let agents_list = this.$store.getters["assignableAgentLists"];
				let allAgentsList = [
					{
						id: null,
						name: "All",
						agent: {
							agent_id: "All",
							id: null,
							user_id: null,
							payload: null,
						},
					},
				];
				allAgentsList = allAgentsList.concat(agents_list);
				return allAgentsList;
			},
			customFilteredApplications() {
				// this.getAgentApplicationsListDebounced();

				let filteredApplications = this.agent_applications_list.filter(
					(application) => {
						if (this.applicationFilters?.alerts === null) {
							return true;
						}

						let alertColor =
							this.$helpers.getApplicationAlertDotColor(application);

						if (!alertColor) {
							return false;
						}

						if (this.applicationFilters.alerts == "All Alerts") {
							return !!alertColor;
						}

						return !!this.applicationFilters.alerts
							.toString()
							.toLowerCase()
							.includes(alertColor.toString().toLowerCase());
					}
				);

				return filteredApplications;
			},
		},
		methods: {
			refresh() {
				this.getApplicationsListDebounced();
			},
			getApplicationsListDebounced: debounce(function () {
				this.getApplicationsList();
			}, 500),
			changeSelectedAgentId() {
				this.$store.state.selected_agent_id = this.selectedAgent?.agent?.id;
				this.refresh();
			},
			async getApplicationsList() {
				let filters = { address: this.searchString };
				let application_source = ["File Upload"];

				this.$store.dispatch("application/getAgentApplicationsList", {
					filters,
					application_source,
				});
			},
			showApplicationDetails(application) {
				return {
					name: "application-details",
					params: { application_id: application.application_id },
				};
			},
		},
	};
</script>
